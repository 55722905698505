import{get,put,post,del}from"./base";
import qs from "qs";

/**题库管理 **/


//获取题目列表
export function  getTitleExam(tName){
    return get('/v3/questiontypes',{tName})
}
//查询
export function getTitleList(tName){
    return get('/v3/questions',{tName})
}
export function getTitleId(id){
    return get('/v3/questiontype/' + id)
}
//新增题目分类
export function postTitleType(byName,data){
    return post('/v3/questiontype' + '?byName=' + byName,data)
}
export function putTitleType(id,data){
    return put('/v3/questiontype/' + id,data)
}
//新增题目
export function postTitleExam(byName,data){
    return post('/v3/question' + '?byName=' + byName,data)
}
//修改题目
export function putTitleExam(id,data){
    return put('/v3/question/'+ id,data)
}
//删除题目
export function delTitleExam(id){
    return del('/v3/question/'+ id)
}

/**职称考试分类 **/
export function  getExamType(pageNum){
    return get('/v3/examtypes',{pageNum})
}
export function  getExamTypeId(id){
    return get('/v3/examtype/' + id)
}
export function  getTypes(identify){
    return get('/v3/jobtitles',{identify,'pageNum':1,'pageSize':9999})
}
//新增
export function postExamType(byName,data){
    return post('/v3/examtype' + '?byName=' + byName,data)
}
//修改
export function putExamType(id,data){
    return put('/v3/examtype/'+ id,data)
}
export function putExamOpen(id){
    return put('/v3/examIsOpen/' +id)
}

//删除
export function delExamType(id){
    return del('/v3/examtype/'+ id)
}


/**同步练习分类 **/
export function  getExercisesType(){
    return get('/v3/exetypes')
}
export function  getTitleExamList(){
    return get('/v3/examtypes',{'pageNum' :1,'pageSize':9999})
}
export function  getExercisesTypeId(id){
    return get('/v3/exetype/' +id)
}
export function postExercisesType(byName,data){
    return post('/v3/exetype' + '?byName=' + byName,data)
}
export function putExercisesType(id,data){
    return put('/v3/exetype/'+ id,data)
}
export function  delExercisesType(id){
    return del('/v3/exetype/' + id)
}

/** 同步练习管理 **/
//获取练习
export function  getExercises(pageNum){
    return get('/v3/exes',{pageNum})
}
export function  getExercisesId(id){
    return get('/v3/exe/' +id)
}
//新增练习
export function postExercises(byName,data){
    return post('/v3/exe' + '?byName=' + byName,data)
}
//修改练习
export function putExercises(id,data){
    return put('/v3/exe/'+ id,data)
}
//删除练习
export function  delExercises(id){
    return del('/v3/exe/' + id)
}
//获取题目
export function  getExam(id,pageNum,name){
    return get('/v3/exequestion/' + id, {pageNum,name})
}
export function postExam(exeId,byName,data){
    return post('/v3/exequestion' +'?exeId=' + exeId + '&byName=' + byName,data)
}
//上移练习
export function postUpExercises(id,eid){
    return post('/v3/moveUpExe/' + id + '/' +eid)
}
//下移练习
export function postDownExercises(id,eid){
    return post('/v3/moveDownExe/' + id + '/' +eid)
}
//上移题目
export function postUpExam(id,eid){
    return post('/v3/moveUpq/' + id + '/' +eid)
}
//下移题目
export function postDownExam(id,eid){
    return post('/v3/moveDownq/' + id + '/' +eid)
}

/** 模拟练习 **/
export function getTestList(){
    return get('/v3/mocks')
}

export function getTestId(id){
    return get('/v3/mock/' + id)
}
export function postTest(byName,data){
    return post('/v3/mock'+ '?byName=' + byName,data)
}
export function putTest(id,data){
    return put('/v3/mock/' + id,data)
}
//练习上移
export function postTestUp(id,eid){
    return post('/v3/moveUpMock/' + id + '/' +eid)
}
//练习下移
export function postTestDown(id,tid){
    return post('/v3/moveDownMoke/' +id +'/' +tid)
}
//获取题目
export function  getTextExam(id,pageNum,name){
    return get('/v3/mockquestions/' + id, {pageNum,name})
}
export function delTest(id){
    return  del('/v3/mock/' + id)
}
//上移题目
export function postUpTextExam(id,mid){
    return post('/v3/moveUpm/' + id + '/' +mid)
}
//下移题目
export function postDownTextExam(id,mid){
    return post('/v3/moveDownm/' + id + '/' +mid)
}
export function postNewTextExam(exeId,byName,data){
    return post('/v3/mockquestion' +'?mockId=' + exeId + '&byName=' + byName ,data)
}
//修改分数
export function putNewExamScore(id,data){
    return put('/v3/score/' + id ,qs.stringify(data))
}
export function putExamNumScore(id){
    return put('/v3/score/z/' +id)
}
